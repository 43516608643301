import {
  apiGetInfusions, apiGetInfusionOrders, apiGetIngredients, apiGetStaffs
  // , apiGetSelectStaffDialogInfo
} from '@/api/v1/reservations'

export const modalUI = {
  namespaced: true,
  state: {
    modalActive: {
      pop2: false,
      pop2a: false,
      pop2b: false,
      pop4a: false, // 替換人員
      pop6: false,
      pop6a: false
    },
    staffs: '',
    infusions: '', // 可選點滴清單
    ingredients: '', // 所有可選配方
    infusionOrders: '' // 已預約點滴訂單
  },
  mutations: {
    setPop2 (state, val) {
      state.modalActive.pop2 = val
    },
    setPop2a (state, val) {
      state.modalActive.pop2a = val
    },
    setPop2b (state, val) {
      state.modalActive.pop2b = val
    },
    setPop4a (state, val) {
      state.modalActive.pop4a = val
    },
    setPop6 (state, val) {
      state.modalActive.pop6 = val
    },
    setPop6a (state, val) {
      state.modalActive.pop6a = val
    },
    setInfusionOrders (state, val) {
      state.infusionOrders = val
    },
    setInfusions (state, val) {
      state.infusions = val
    },
    setIngredients (state, val) {
      state.ingredients = val
    },
    setStaffs (state, val) {
      state.staffs = val
    }
  },
  actions: {
    async showPop ({ commit }, params) {
      // 點滴訂單
      if (params.modalId === '6a') {
        await apiGetInfusionOrders(params).then(data => {
          if (data.error) {
            console.log(data.error.message)
          } else {
            console.log(data)
            commit('setInfusionOrders', data)
            commit('setPop' + params.modalId, true)
          }
        })
      } else if (params.modalId === '6') {
        // 可選點滴與內容
        await apiGetInfusions(params).then(data => {
          if (data.error) {
            console.log(data.error.message)
          } else {
            commit('setInfusions', data)
            apiGetIngredients().then(data => {
              if (data.error) {
                console.log(data.error.message)
              } else {
                commit('setIngredients', data)
                commit('setPop' + params.modalId, true)
              }
            })
          }
        })
      } else if (params.modalId === '4a' || params.modalId === '2a') {
        // 選人（帶路）
        await apiGetStaffs(params).then(data => {
          if (data.error) {
            console.log(data.error.message)
          } else {
            commit('setStaffs', data.content) // TODO: handle no content
            commit('setPop' + params.modalId, true)
          }
        })
      } else {
        commit('setPop' + params.modalId, true)
      }
    },
    closePop ({ commit }, modalId) {
      commit('setPop' + modalId, false)
    }
  }
}
