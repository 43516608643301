import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import { Role } from '../constants/role'
import Swal from '../../node_modules/sweetalert2/dist/sweetalert2'
import VueBodyClass from 'vue-body-class'

const DEFAULT_TITLE = 'iSkin尚行美健康聯合診所'
const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/wscreen',
    name: 'WScreen',
    component: () => import('@/views/WScreen.vue'),
    meta: {
      title: '前台總控',
      authorize: [Role.Admin, Role.FrontDesk]
    }
  },
  {
    path: '/nscreen',
    name: 'NScreen',
    component: () => import('@/views/NScreen.vue'),
    meta: {
      title: '預約報到',
      authorize: [Role.Admin, Role.Staff, Role.FrontDesk]
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: {
      title: '登入'
    }
  },
  {
    path: '/daily-report',
    name: 'DailyReport',
    component: () => import('@/views/DailyReport.vue'),
    meta: {
      title: '總表',
      authorize: [Role.Admin, Role.Staff, Role.FrontDesk]
    }
  },
  {
    path: '/daily-report-medical',
    name: 'DailyReportMedical',
    component: () => import('@/views/DailyReportMedical.vue'),
    meta: {
      title: '醫療總表',
      authorize: [Role.Admin, Role.Staff, Role.FrontDesk]
    }
  },
  {
    path: '/daily-report-beauty',
    name: 'DailyReportBeauty',
    component: () => import('@/views/DailyReportBeauty.vue'),
    meta: {
      title: '美療總表',
      authorize: [Role.Admin, Role.Staff, Role.FrontDesk]
    }
  },
  // Admin Section
  {
    path: '/admin',
    redirect: '/admin/login'
  },
  {
    path: '/admin/login',
    name: 'AdminLogin',
    component: () => import('@/views/admin/Login.vue'),
    meta: {
      title: '後台登入',
      bodyClass: 'scroll'
    }
  },
  {
    path: '/admin/clients',
    name: 'AdminClients',
    component: () => import('@/views/admin/Clients.vue'),
    meta: {
      title: '客戶管理',
      authorize: [Role.Admin],
      bodyClass: 'scroll'
    }
  },
  {
    path: '/admin/clients/:clientId',
    name: 'AdminClientDetail',
    props: true,
    component: () => import('@/views/admin/ClientDetail.vue'),
    meta: {
      title: '客戶管理',
      authorize: [Role.Admin],
      bodyClass: 'scroll'
    }
  },
  {
    path: '/admin/staffs',
    name: 'AdminStaffs',
    component: () => import('@/views/admin/Staffs.vue'),
    meta: {
      title: '員工管理',
      authorize: [Role.Admin, Role.FrontDesk],
      bodyClass: 'scroll'
    }
  },
  {
    path: '/admin/staffs/:staffId',
    name: 'AdminStaffDetail',
    props: true,
    component: () => import('@/views/admin/StaffDetail.vue'),
    meta: {
      authorize: [Role.Admin, Role.FrontDesk],
      bodyClass: 'scroll'
    }
  },
  {
    path: '/admin/staffs/:staffId/report',
    name: 'AdminStaffReport',
    props: true,
    component: () => import('@/views/admin/StaffReport.vue'),
    meta: {
      authorize: [Role.Admin, Role.FrontDesk],
      bodyClass: 'scroll'
    }
  },
  {
    path: '/admin/spots',
    name: 'AdminSpots',
    component: () => import('@/views/admin/Spots.vue'),
    meta: {
      authorize: [Role.Admin, Role.FrontDesk],
      bodyClass: 'scroll'
    }
  },
  {
    path: '/admin/spots/:spotId',
    name: 'AdminSpotDetail',
    props: true,
    component: () => import('@/views/admin/SpotDetail.vue'),
    meta: {
      authorize: [Role.Admin, Role.FrontDesk],
      bodyClass: 'scroll'
    }
  },
  {
    path: '/admin/treatment-types',
    name: 'AdminTreatmentTypes',
    component: () => import('@/views/admin/TreatmentTypes.vue'),
    meta: {
      authorize: [Role.Admin, Role.FrontDesk],
      bodyClass: 'scroll'
    }
  },
  {
    path: '/admin/treatment-types/:treatmentTypeId',
    name: 'AdminTreatmentTypeDetail',
    props: true,
    component: () => import('@/views/admin/TreatmentTypeDetail.vue'),
    meta: {
      authorize: [Role.Admin, Role.FrontDesk],
      bodyClass: 'scroll'
    }
  },
  {
    path: '/admin/treatments',
    name: 'AdminTreatments',
    component: () => import('@/views/admin/Treatments.vue'),
    meta: {
      authorize: [Role.Admin, Role.FrontDesk],
      bodyClass: 'scroll'
    }
  },
  {
    path: '/admin/treatments/:treatmentId',
    name: 'AdminTreatmentDetail',
    props: true,
    component: () => import('@/views/admin/TreatmentDetail.vue'),
    meta: {
      authorize: [Role.Admin, Role.FrontDesk],
      bodyClass: 'scroll'
    }
  },
  {
    path: '/admin/treatment-time',
    name: 'AdminTreatmentTime',
    component: () => import('@/views/admin/TreatmentTime.vue'),
    meta: {
      authorize: [Role.Admin, Role.FrontDesk],
      bodyClass: 'scroll'
    }
  },
  {
    path: '/admin/vacation',
    name: 'AdminVacation',
    component: () => import('@/views/admin/Vacation.vue'),
    meta: {
      authorize: [Role.Admin, Role.FrontDesk],
      bodyClass: 'scroll'
    }
  },
  {
    path: '/admin/users',
    name: 'AdminUsers',
    component: () => import('@/views/admin/Users.vue'),
    meta: {
      authorize: [Role.Admin],
      bodyClass: 'scroll'
    }
  },
  {
    path: '/admin/users/:userId',
    name: 'AdminUserDetail',
    props: true,
    component: () => import('@/views/admin/UserDetail.vue'),
    meta: {
      authorize: [Role.Admin],
      bodyClass: 'scroll'
    }
  },
  {
    path: '/admin/overall-report',
    name: 'AdminOverallReport',
    component: () => import('@/views/admin/OverallReport.vue'),
    meta: {
      authorize: [Role.Admin],
      bodyClass: 'scroll'
    }
  },
  {
    path: '/admin/work-detail',
    name: 'AdminWorkDetail',
    component: () => import('@/views/admin/WorkDetail.vue'),
    meta: {
      authorize: [Role.Admin],
      bodyClass: 'scroll'
    }
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/login',
    bodyClass: 'scroll'
  }
]

const vueBodyClass = new VueBodyClass(routes)

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes
})

router.beforeEach((to, from, next) => {
  vueBodyClass.guard(to, next)
  const { authorize } = to.meta
  const currentUser = store.state.auth.user // localStorage.getItem('user')

  // trying to access a restricted page + not logged in
  const loginPath = to.path.includes('admin') ? '/admin/login' : '/login'

  // redirect to login page
  // console.log(to)
  // console.log(`autho = ${authorize}`)
  if (authorize) {
    if (!currentUser) {
      return next(loginPath)
    }
    // check if route is restricted by role
    if (authorize.length && !authorize.includes(currentUser.roles[0])) {
      // role not authorised so redirect to login page
      Swal.fire({ title: '權限不足', icon: 'warning' })
      return next(loginPath)
    }
  }
  window.document.title = to.meta.title ? `${to.meta.title} - ${DEFAULT_TITLE}` : DEFAULT_TITLE
  next()
})

export default router
