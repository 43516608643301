import req from '@/api/v1/request'
import { baseURL } from '@/api/v1/host'

// 訂單列表
export const apiGetReservations = params => req.get('reservations', params)
// 身份列表
export const apiGetJobs = params => req.get('jobs', params)
// 專長列表
export const apiGetSpecialties = params => req.get('specialties', params)
// 場地列表
export const apiGetSpots = params => req.get('spots', params)
// 員工列表
export const apiGetStaffs = params => req.get('staffs', params)
// 報到
export const apiCheckin = (params, data = {}) => req.post(`reservations/${params.reservationId}/checkin`, data)
// 取消
export const apiCancel = params => req.delete(`reservations/${params.reservationId}`)
// 簡訊通知
export const apiSMS = (params, data = {}) => req.post(`reservations/${params.reservationId}/sms`, data)
// 帶位
export const apiTakeToSpot = params => req.post(`reservations/${params.reservationId}/spot`, params)
// 點滴訂單列表
export const apiGetInfusionOrders = params => req.get(`reservations/${params.reservationId}/infusions`, params)
// 點滴配方列表
export const apiGetInfusions = () => req.get('infusions')
// 點滴內容列表
export const apiGetIngredients = () => req.get('ingredients')
// 新增點滴訂單
export const apiNewInfusionOrder = params => req.post(`reservations/${params.reservationId}/infusions`, params.data)
// 更新點滴訂單
export const apiUpdateInfusionOrder = params => req.put(`reservations/${params.reservationId}/infusions/${params.infusionOrderId}`, params.data)
// 刪除點滴訂單
export const apiDeleteInfusionOrder = params => req.delete(`reservations/${params.reservationId}/infusions/${params.infusionOrderId}`)
// 換人
export const apiModifyTask = params => req.patch(`reservations/${params.reservationId}/tasks/${params.taskId}`, params)
// 加人
export const apiAddTask = (params) => req.post(`reservations/${params.reservationId}/tasks`, params)
// 客戶分類
export const apiGetPatientTypes = () => req.get('patient_types')
// 客戶搜索
export const apiGetPatient = params => req.get('patient', params)
// 客戶列表 (分頁)
export const apiListPatients = params => req.get('patients', params)
// 建立新客戶
export const apiCreatePatient = params => req.post('patients', params.data)
// 撈客戶資料
export const apiGetPatientById = id => req.get(`patients/${id}`)
// 更新客戶
export const apiUpdatePatient = params => req.put(`patients/${params.patientId}`, params.data)
// 取得療程 (一分類)
export const apiGetTreatmentTypes = () => req.get('treatment_types')
// 預約
export const apiMakeReservation = params => req.post('reservations', params)
// 醫生
export const apiGetDoctors = treatmentId => req.get(`/treatments/${treatmentId}/doctors`)
// 已經被預約的時間
export const apiGetScheduleTime = (treatmentId, params) => req.get(`/treatments/${treatmentId}/schedules`, params)
// 每日總表
export const apiGetDailyReport = (staffId, params) => req.get(`/staffs/${staffId}/daily`, params)
// Photo
export const photoUrl = (what, id) => (`${baseURL}/${what}/${id}/photo`)
// 取得改期資訊
export const apiGetResheduleData = (reservationId) => req.get(`reservations/${reservationId}/reschedule`)
// 取得點滴任務
export const apiGetInfusionTasks = (params) => req.get('infusion-tasks', params)
// 更新點滴任務(指派)
export const apiPatchInfusionTasks = (params) => req.patch(`infusion-tasks/${params.id}`, params)
// 取得 Latest 1 feedback. TODO: 改為 Web Notification
export const apiGetLatestFeedback = () => req.get('feedbacks/latest')
// 讀取 feedback
export const apiReadFeedback = (feedbackId) => req.post(`feedbacks/${feedbackId}`)
// 取得選人對話框所需的資料
export const apiGetSelectStaffDialogInfo = (params) => req.get(`reservations/${params.reservationId}/select-staff-dialog`, params)
// 原地進行
export const apiProceedHere = (reservationId) => req.post(`reservations/${reservationId}/proceed`)
// 取得預約時間表
export const apiGetTimeSlots = (params) => req.get('system/settings/timeslots', params)
// 取得新總表
export const apiGetDailyReportNew = (params) => req.get('report/daily', params)
// 更新聯繫情況
export const apiPostContactStatus = (reservationId, params) => req.post(`reservations/${reservationId}/contactstatus`, params)
// 變更療程
export const apiChangeTreatment = (reservationId, params) => req.post(`reservations/${reservationId}/change-treatment`, params)
