export const getBaseUrl = function () {
  if (process.env.NODE_ENV === 'development') {
    return '//localhost:8080/api/v1'
  } else {
    // return '//iskin.farmdroid.com:8443/api/v1'
    if (process.env.VUE_APP_PLATFORM === 'STAGE') {
      return '//iskin-aws.farmdroid.com:8443/api/v1'
    } else {
      return '//cms.iskin.com.tw:8443/api/v1'
    }
    // const host = location.host.split(':')[0]
    // return `${host}:8443/api/v1`
  }
}
export const baseURL = getBaseUrl()
