import { createStore } from 'vuex'
import { auth } from './modules/auth'
import { modalUI } from './modules/modalUI'

export default createStore({
  modules: {
    auth,
    modalUI
  }
})
