<template>
  <router-view />
</template>

<style lang='scss'>
@import '~@/assets/styles/site.css';
@import '../node_modules/sweetalert2/dist/sweetalert2.min.css';

.card {
  &__process-identity-wrap {
    cursor: pointer;
  }
}
.swal2-content {
  font-size: 1.6em;
}
.swal2-styled.swal2-confirm {
  background-color: var(--color-reservation);
  width: 14rem;
  display: block;
  margin: 0 auto;
  font-size: 1.6rem;
  color: #fff;
  text-align: center;
  height: 5rem;
  border-radius: 5rem;
  border: 0;
}
.swal2-styled.swal2-cancel {
  // background-color: var(--color-reservation);
  width: 14rem;
  display: block;
  margin: 0 auto;
  font-size: 1.6rem;
  // color: #fff;
  text-align: center;
  height: 5rem;
  border-radius: 5rem;
  border: 0;
}
// .identity {
//   cursor: pointer;
// }
</style>
