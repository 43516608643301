import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { createI18n } from 'vue-i18n'
import VueSweetalert2 from 'vue-sweetalert2'

const app = createApp(App)

// show dev tools under dev
if (process.env.NODE_ENV === 'development') {
  // if ('__VUE_DEVTOOLS_GLOBAL_HOOK__' in window) {
  //   window.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = app
  // }
  app.config.devtools = true
}
// Create VueI18n instance with options
const i18n = createI18n({
  locale: 'zh-Hant-TW'
})

app.use(store).use(router).use(VueAxios, axios).use(VueSweetalert2).use(i18n)
app.mount('#app')

console.log(`Running v${process.env.VUE_APP_VERSION} on ${process.env.VUE_APP_PLATFORM} server.`)
