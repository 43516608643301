/**
 * axios 封裝
 */
import axios from 'axios'
// import qs from 'qs'
import { toLogin, to403 } from './utils'
import { baseURL } from '@/api/v1/host'

// 創建axios實例
const service = axios.create({
  baseURL: baseURL, // api的base_url
  withCredentials: true, // 跨域請求時是否發送cookies
  timeout: 5000 // 請求超時設置
})

// 請求攔截器
service.interceptors.request.use(config => {
  // 請求前做點什麼？
  if (!config.data) {
    config.data = {}
  }

  // console.log(config)
  // 設置公共參數
  // console.log(qs.stringify(config.data))
  return config
}, error => {
  // 處理請求錯誤
  console.log(error) // 用於調試
  return Promise.reject(error)
})

// error handling
const errorHandle = (status, msg) => {
  switch (status) {
    case 400:
      console.log(msg)
      break
    case 401:
      console.log('登入過期')
      setTimeout(() => {
        toLogin()
      }, 500)
      return true
    case 403:
      to403()
      break
    case 404:
      console.log('伺服器回應 404')
      break
    case 500:
      console.log('伺服器出錯')
      break
    case 503:
      console.log('服務失效')
      break
    default:
      console.log(`連接錯誤${msg}`)
  }
  return false
}

// 響應攔截器
service.interceptors.response.use(response => {
  // let res = respone.data; // 如果返回的結果是data.data的，嫌麻煩可以用這個，return res
  return response
}, (error) => {
  const { response } = error
  if (response) {
    if (!errorHandle(response.status, response.data.error)) {
      return Promise.reject(response)
    }
  } else {
    if (!window.navigator.onLine) {
      console.log('網路不通，請確認網路連線。')
    }
  }

  return Promise.resolve(response)
})

export function fetch (url, params = {}) {
  return new Promise((resolve, reject) => {
    service.get(url, {
      params: params
    }).then(response => {
      resolve(response.data)
    },
    err => {
      reject(err)
    }
    ).catch(err => {
      reject(err)
    })
  })
}

export function post (url, data = {}) {
  console.log(`POST ${url} --data`)
  console.log(data)
  return new Promise((resolve, reject) => {
    service.post(url, data).then(
      response => {
        resolve(response.data)
      },
      err => {
        reject(err)
      }
    )
  })
}

export function patch (url, data = {}) {
  console.log(`PATCH ${url} --data`)
  console.log(data)
  return new Promise((resolve, reject) => {
    service.patch(url, data).then(
      response => {
        resolve(response.data)
      },
      err => {
        reject(err)
      }
    )
  })
}

export function remove (url, data = {}) {
  return new Promise((resolve, reject) => {
    service.delete(url, data).then(
      response => {
        resolve(response.data)
      },
      err => {
        reject(err)
      }
    )
  })
}

export function put (url, data = {}) {
  return new Promise((resolve, reject) => {
    service.put(url, data).then(
      response => {
        resolve(response.data)
      },
      err => {
        reject(err)
      }
    )
  })
}
// 將封裝的方法打包起來
export default {
  get: function (endpoint, paramObj) {
    return fetch(endpoint, paramObj)
  },
  post: function (endpoint, paramObj) {
    return post(endpoint, paramObj)
  },
  put: function (endpoint, paramObj) {
    return put(endpoint, paramObj)
  },
  patch: function (endpoint, paramObj) {
    return patch(endpoint, paramObj)
  },
  delete: function (endpoint, paramObj) {
    return remove(endpoint, paramObj)
  }
}
